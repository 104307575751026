import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useFormik } from "formik";

import firebase from "./../utilities/firebase.js";

const Invite = () => {
  /*const [brandInfo, setBrandInfo] = useState({
    name: '',
    brandId: '',
  })*/

  const [invitationId, setInvitationId] = useState({});
  const [isSigningUp, setIsSigningUp] = useState(false);

  useEffect(() => {
    //Gets the pathname from URL
    let pathname = window.location.pathname;
    //Extracts ID
    let invitationId = pathname.split("invite/").pop();

    //Removes any slashes, if any
    invitationId = invitationId.replace(/\//g, "");

    setInvitationId(invitationId);

    //getBrandFromId(brandId)
  }, []);

  const validate = (values) => {
    console.log(values);
    console.log("first name: ", values.firstName);
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 200) {
      errors.firstName = "Must be 200 characters or less";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    } else if (values.lastName.length > 200) {
      errors.lastName = "Must be 200 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Must be 8 characters or more";
    }

    console.log(errors);
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      var signUp = firebase.functions().httpsCallable("signUp");

      setIsSigningUp(true);

      signUp({ formValues: values, invitationId: invitationId }).then(
        (result) => {
          firebase
            .auth()
            .signInWithEmailAndPassword(values.email, values.password)
            .then((userCredential) => {
              if (result.data.userRole === "sales-rep" || result.data.userRole === "manager" || result.data.userRole === "admin") {
                navigate("/app/dashboard");
              } else {
                navigate("/app/apply");
              }
            })
            .catch((error) => {
              setIsSigningUp(false);
              //var errorCode = error.code
              //var errorMessage = error.message
            });
        }
      );

      /*var createProspectUser = firebase
        .functions()
        .httpsCallable('createProspectUser')

      createProspectUser({
        formValues: values,
        brandId: brandInfo.brandId,
        brandName: brandInfo.brandName,
      }).then(result => {
        console.log('result: ', result)
        firebase
          .auth()
          .signInWithEmailAndPassword(values.email, values.password)
          .then(userCredential => {
            console.log('userCredential: ', userCredential)
            // Signed in
            navigate('/apply')
          })
          .catch(error => {
            var errorCode = error.code
            var errorMessage = error.message
          })
       })*/
    },
  });

  /*const getBrandFromId = brandId => {
    var brand = firebase.functions().httpsCallable('getBrandFromId')

    brand(brandId).then(result => {
      console.log('RESULT: ', result)
      setBrandInfo({ name: result.data, brandId: brandId })
      console.log('RESULT2: ', brandInfo.brandId)
    })
  }*/

  //auth.signOut()
  console.log("II");

  return !isSigningUp ? (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign up to Ally
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          {`Already a user? `}
          <a
            href="/login"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Log in here
          </a>
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={formik.handleSubmit}>
            <div className="flex flex-row">
              <div className="mr-2">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="given-name"
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    className={`${formik.errors.firstName && formik.touched.firstName
                      ? "border-red-800"
                      : "border-gray-300"
                      } appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {formik.errors.firstName && formik.touched.firstName && (
                    <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                      {formik.errors.firstName}
                    </span>
                  )}
                </div>
              </div>
              <div className="ml-3">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    autoComplete="family-name"
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    className={`${formik.errors.lastName && formik.touched.lastName
                      ? "border-red-800"
                      : "border-gray-300"
                      } appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {formik.errors.lastName && formik.touched.lastName && (
                    <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                      {formik.errors.lastName}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address (same as you got the invite)
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={`${formik.errors.email && formik.touched.email
                    ? "border-red-800"
                    : "border-gray-300"
                    } appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                {formik.errors.email && formik.touched.email && (
                  <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                    {formik.errors.email}
                  </span>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={`${formik.errors.password && formik.touched.password
                    ? "border-red-800"
                    : "border-gray-300"
                    } appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                {formik.errors.password && formik.touched.password && (
                  <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                    {formik.errors.password}
                  </span>
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 mt-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-screen">
      <svg
        className="animate-spin h-5 w-5 flex justify-center align-middle text-black"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      {/*<p className="ml-2">Loading...</p>*/}
    </div>
  );
};

export default Invite;
